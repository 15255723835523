import React, { FC, useEffect, useRef, useState } from 'react';
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, SelectProps, TextField, useMediaQuery, useTheme } from '@mui/material';
import { CatchRegistrationWeightWrapper } from './CatchRegistrationWeight.styled';
import PostCatchBody from '../../../../../Models/CatchRegistrationModels/PostCatchBody';
import CatchRegistration from '../../../../../Models/CatchRegistrationModels/CatchRegistration';
import ScaleIcon from '@mui/icons-material/Scale';
import PercentIcon from '@mui/icons-material/Percent';
import CommentIcon from '@mui/icons-material/Comment';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import WeightNoteTypes from '../../../../../Constants/WeightNoteTypes';

interface CatchRegistrationWeightProps {
  handleFormChange: (updatedForm: PostCatchBody) => void;
  weightItem: CatchRegistration | undefined;
  disabled: boolean;
  selectedWeightNote: WeightNote;
  isExported: boolean;
  /** (e) Scallops => Hörpudiskur */
  stockIsScallops: boolean;
  splitWeight?: number
}

/**
 * Functional component for CatchRegistrationWeight.
 * Displays the weight section of the catch registration modal.
 * @param props 
 * - takes in a weightItem object
 * - takes in a function to be called when the form changes
 * - takes in a the selected weight note.
 * @returns {JSX} - Responsible for returning the weight section of the catch registration modal.
 */

const CatchRegistrationWeight: FC<CatchRegistrationWeightProps> = (props) => {

  const [weight, setWeight] = useState<number | ''>('');
  const [icePercentage, setIcePercentage] = useState<number | ''>('');
  const [comment, setComment] = useState<string | ''>('');
  const [selectOpen, setSelectOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const MAXIMUM_ICE_PERCENTAGE = props.stockIsScallops ? 4 : 3;
  const MAXIMUM_WEIGHT = 9999999;
  const MIN_WEIGHT = 0;

  const icePercentageSelectRef = useRef<HTMLDivElement & SelectProps>(null);

  useEffect(() => {
    if (props.weightItem) {
      setWeight(props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? props.weightItem.sampleWeight ?? '' : props.weightItem.weight ?? '');
      setIcePercentage(props.weightItem.icePercentage ?? '');
      setComment(props.weightItem.userComment ?? '');
    }
    else {
      setWeight('');
      setIcePercentage('');
      setComment('');
    }
  }, [props.selectedWeightNote.weightNoteType.id, props.weightItem]);

  // Effect hook to adjust icePercentage based on isExported status and stockIsScallops status
  useEffect(() => {
    if (!props.isExported && Number(icePercentage) > MAXIMUM_ICE_PERCENTAGE) {
      setIcePercentage('');
      props.handleFormChange({ icePercentage: 0 });
    }
    if (!props.stockIsScallops && Number(icePercentage) > MAXIMUM_ICE_PERCENTAGE) {
      setIcePercentage('');
      props.handleFormChange({ icePercentage: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isExported, props.stockIsScallops]);

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newWeight = Number(event.target.value);
    // Check if the number is an integer
    if (!Number.isInteger(newWeight)) return;
    if (newWeight < MIN_WEIGHT || newWeight > MAXIMUM_WEIGHT) return;
    if (props.splitWeight && newWeight > props.splitWeight-1) return;
    setWeight(newWeight);
    const updatedForm: PostCatchBody = {};
    if (props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID) {
      updatedForm.sampleWeight = newWeight;
    } else {
      updatedForm.weight = newWeight;
    }
    props.handleFormChange(updatedForm);
  };

  const handleIcePercentageChange = (icePercentage: number) => {
    if (!props.isExported && (icePercentage < 0 || icePercentage > MAXIMUM_ICE_PERCENTAGE)) return;
    setIcePercentage(icePercentage);
    const updatedForm: PostCatchBody = {
      icePercentage: icePercentage
    };
    props.handleFormChange(updatedForm);
    setSelectOpen(false); // Close the dropdown after selection.
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    const updatedForm: PostCatchBody = {
      userComment: event.target.value
    };
    props.handleFormChange(updatedForm);
  };

  return (
    <CatchRegistrationWeightWrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
        <TextField
          fullWidth
          type="number"
          disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
          id="weight"
          label={props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? "Vigtun úrtaks" : "Vigt"}
          variant="outlined"
          onChange={handleWeightChange}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
              e.preventDefault()
            }
          }}
          value={weight ? weight : weight !== 0 ? weight : ''}
          InputProps={{
            inputProps: {
              min: MIN_WEIGHT,
              max: MAXIMUM_WEIGHT
            },
            startAdornment: (
              <InputAdornment position="start">
                <ScaleIcon color='primary' />
              </InputAdornment>
            )
          }}
        />
        {props.selectedWeightNote.weightNoteType.id !== WeightNoteTypes.TO_REWEIGHING &&
          props.selectedWeightNote.weightNoteType.id !== WeightNoteTypes.TO_HOME_REWEIGHING &&
          props.selectedWeightNote.weightNoteType.id !== WeightNoteTypes.PRODUCT_SAMPLE_ID &&
          !props.isExported &&
          (
            <TextField
              fullWidth
              type="number"
              disabled={props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID || props.selectedWeightNote?.isClosed || props.disabled}
              id="icePercentage"
              label="Ís (%)"
              variant="outlined"
              onChange={(e) => handleIcePercentageChange(Number(e.target.value))}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                  e.preventDefault()
                }
              }}
              value={icePercentage ? icePercentage : ''}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: MAXIMUM_ICE_PERCENTAGE
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PercentIcon color='primary' />
                  </InputAdornment>
                )
              }}
            />
          )}
        {/** If the destiny is exported theese are the only availeable values to choose from */}
        {props.isExported && (
          <FormControl fullWidth>
            <InputLabel >Ís (%)</InputLabel>
            <Select
              value={icePercentage}
              open={selectOpen}
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
              label="Ís (%)"
              onChange={(e) => handleIcePercentageChange(Number(e.target.value))}
              ref={icePercentageSelectRef}
            >
              <MenuItem
                value={0}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    handleIcePercentageChange(0);
                  }
                }}
              >
                0 %
              </MenuItem>
              <MenuItem
                value={0.6}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    handleIcePercentageChange(0.6);
                  }
                }}
              >
                0,6 %
              </MenuItem>
              <MenuItem
                value={7}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    handleIcePercentageChange(7);
                  }
                }}
              >
                7 %
              </MenuItem>
              <MenuItem
                value={12}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    handleIcePercentageChange(12);
                  }
                }}
              >
                12 %
              </MenuItem>
            </Select>
            <FormHelperText>Fastar ísprósentur fyrir útflutning</FormHelperText>
          </FormControl>
        )}
      </div>
      <TextField
        sx={{ width: '100%' }}
        type="string"
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        id="comment"
        label="Athugasemd"
        variant="outlined"
        onChange={handleCommentChange}
        value={comment ? comment : ''}
        InputProps={{
          inputProps: {
            maxLength: 100
          },
          startAdornment: (
            <InputAdornment position="start">
              <CommentIcon color='primary' />
            </InputAdornment>
          )
        }}
      />
    </CatchRegistrationWeightWrapper>
  );
};

export default CatchRegistrationWeight;
