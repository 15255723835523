import React, { useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface Doc {
  title: string;
  items: DocItem[];
}

interface DocItem {
  question: string;
  answer: string | JSX.Element;
}

const ScribeIframe = ({ src }: { src: string }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => setLoading(false);
  const handleError = () => setError(true);

  return (
    <Box position="relative" width="100%" height="500px">
      {loading && !error && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6" color="error">Failed to load content</Typography>
        </Box>
      )}
      {!error && (
        <iframe
          src={src}
          width="100%"
          height="500"
          allowFullScreen
          style={{ border: 'none', borderRadius: '8px' }}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </Box>
  );
};

const docs: Doc[] = [
  {
    title: "Landanir",
    items: [
      {
        question: "Hvernig skrái ég nýja löndun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_a_ad_bua_til_londun__H0i0A9D0Rp6IQ7GlAkLz4g?as=scrollable" />
        ),
      },
      {
        question: "Hvernig breyti ég löndun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Uppfaera_londun__xMzhjhRfSo2gPj5gCnHYdw?as=scrollable" />
        ),
      },
      {
        question: "Hvernig eyði ég löndun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Eyda_londun__N8EjtWYkRs-rFicKRUlitA?as=scrollable" />
        ),
      },
      {
        question: "Hvernig loka ég löndun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hverni_a_ad_ljuka_londun__c0b-vCiLSc6itG2JhQbOJA?as=scrollable" />
        ),
      }
    ],
  },
  {
    title: "Vigtarnótur",
    items: [
      {
        question: "Hvernig skrái ég nýja vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Stofna_vigtarnotu__Pt2C8siGQRquSQme-W8wWw?as=scrollable" />
        ),
      },
      {
        question: "Hvernig uppfæri ég vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Uppfaera_vigtarnotu__xEpV14F4SSi9y-pmAIcgSw?as=scrollable" />
        ),
      },
      {
        question: "Hvernig eyði ég vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Eyda_vigtarnotu__mh7-fu8zSVSKmBHqDDfIMw?as=scrollable" />
        ),
      },
      {
        question: "Hvernig bakfæri ég vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Bakfaera_vigtarnotu__OoIT4qrHRb2ByxdFevTY6A?as=scrollable" />
        )
      },
      {
        question: "Hvernig breyti ég lokaðri vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_bakfaeri_eg_vigtarnotu__PObTHGyKT9205m1U191lGA?as=scrollable" />
        )
      },
      {
        question: "Hvernig skrái ég netfangahóp?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Stofna_netfangahop__2GH-tUxPQpK_sYXjqrdNQw?as=scrollable" />
        ),
      },
      {
        question: "Hvernig sendi ég vigtarnótu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_a_ad_senda_vigtarnotu__oQYN0OCRRWqcWkQ1hSAcZg?as=scrollable" />
        )
      },
      {
        question: "Hvar sæki ég vigtarnótu til prentunar?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvar_saeki_eg_vigtarnotu_til_prentunar__5vdtr5gvRxC50lAftO7Mjw?as=scrollable" />
        )
      }
    ],
  },
  {
    title: "Vigtanir",
    items: [
      {
        question: "Hvernig skrái ég nýja vigtun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_a_ad_skra_vigtanir__D4HauyAHQxGqLIWKsDDsKQ?as=scrollable" />
        ),
      },
      {
        question: "Hvernig breyti ég vigtun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_breyti_eg_vigtarlinu__xVISHueQQWKCCk8HILrdVQ?as=scrollable" />
        ),
      },
      {
        question: "Hvernig eyði ég vigtun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_eydi_eg_vigtarlinu__IgkUNve8TxmVUegT-N7ciQ?as=scrollable" />
        ),
      },
    ],
  },
  {
    title: "Endurvigtun",
    items: [
      {
        question: "Hvernig skrái ég endurvigtun?",
        answer: (
          <ScribeIframe src="https://scribehow.com/embed/Hvernig_skrai_eg_endurvigtun__fpw8hVTYS-uNjg_rSyUllA?as=scrollable" />
        ),
      },
    ]
  }
];

export default docs;